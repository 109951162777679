import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import ContentBlock from 'src/components/contentBlock';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "spacer"
    }}>{`Spacer()`}</h1>
    <p>{`Adds the maximum available space when placed between adjacent views.`}</p>
    <ContentBlock imgName="Stacks-spacer.png" mdxType="ContentBlock">
  <code className="clike">
    {`
/* 
    @frozen struct Spacer
*/
VStack(spacing: 60) {
    VStack {
        Text("Above Spacer()")
            .padding()
        Spacer()
        Text("Below Spacer()")
    }
    .padding()
    .border(Color.red, width: 3)\n
    HStack() {
        Text("Left of Spacer()")
        Spacer()
        Text("Right of Spacer()")
    }
    .padding()
    .border(Color.red, width: 3)
}
    `}
  </code>
    </ContentBlock>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      